import Alpine from 'alpinejs';

Alpine.data("CourseSelector", (combinations) => ({
	options: ["BLS", "ACLS", "PALS"],
	combinations: JSON.parse(combinations),
	selected: [],
	courseId: null,
	init() {
		this.$watch("selected", () => {
			this.findComboFromSelected();
		});
		this.getStateFromUrl();
	},
	isSelected(option) {
		return this.selected.includes(option);
	},
	toggleSelected(option) {
		if (this.selected.includes(option)) {
			this.selected = this.selected.filter((selected) => selected !== option);
		} else {
			this.selected.push(option);
		}
		this.updateUrlWithSelected();
	},
	updateUrlWithSelected() {
		let url = new URL(window.location.href);
		url.searchParams.set("courses", this.selected.join("-"));
		window.history.pushState({}, "", url);
	},
	getStateFromUrl() {
		let url = new URL(window.location.href);
		let selected = url.searchParams.get("courses");
		if (selected) {
			this.selected = selected.split("-");
		}
	},
	findComboFromSelected() {
		let selected = this.selected.sort().join("");
		let selected_combination = this.combinations.find((combination) => {
			return combination.combo.sort().join("") === selected;
		});
		// Update courseId to the id of the found combination, or reset if not found
		this.courseId = selected_combination ? selected_combination.id : null;
	},
	alertIfNoCourseSelected() {
		if (!this.courseId) {
			alert("Please select a course to continue");
		}
	},
	courseUrl() {
		if (!this.courseId) {
			return null;
		} else {
			return `https://codeone.enrollware.com/enroll?course=${this.courseId}`;
		}
	},
}));
